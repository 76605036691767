// Primeflex additions

@use './theme/variables' as variables;

// Add additional viewport classes ('.min-h-100dvh', '.w-100lvw', etc)
$height: (
  h: 'height',
  'min-h': 'min-height',
  'max-h': 'max-height',
);
$width: (
  w: 'width',
  'min-w': 'min-width',
  'max-w': 'max-width',
);
$units: dv, sv, lv;

@each $direction, $prop in $height {
  @each $unit in $units {
    .#{$direction}-100#{$unit}h {
      #{$prop}: 100 + #{$unit}h;
    }
  }
}

@each $direction, $prop in $width {
  @each $unit in $units {
    .#{$direction}-100#{$unit}w {
      #{$prop}: 100 + #{$unit}w;
    }
  }
}

// Quick reusable secondary background
.bg-secondary {
  background-color: variables.$brandSecondaryColor;
}

.nowrap {
  text-wrap: nowrap;
}
