// Resets for Normalize.css and PrimeNG compat
// May not be the issue with the @layer import at the root
a {
  text-decoration: none;

  // Fix styling on button-styled links
  &.p-button {
    display: inline-flex;
    background-color: revert-layer;
  }
}

// Anchors not directly used in nav elements used for navbars / routing menus
:not(nav) > a {
  // Style default anchor links with primary color
  &:not(.p-button, .p-menuitem-link) {
    &[href],
    &[routerLink] {
      color: var(--primary-color);
      &:hover {
        filter: brightness(85%);
        text-decoration: underline;
      }
    }
  }
}
