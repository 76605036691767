@use 'sass:color';

// Logo colors
$brandPrimaryColor: #fb8500;
$brandSecondaryColor: #042434;

// Set theme color based on brand color
$primaryColor: $brandPrimaryColor;
$primaryDarkColor: color.scale($brandPrimaryColor, $lightness: -10%);
$primaryDarkerColor: color.scale($brandPrimaryColor, $lightness: -20%);

// Disable button focus outlining
$focusOutline: 0;

// Disable sidebar border
$overlayContentBorder: 0 none;
