@use 'primeng/resources/primeng.min.css';
@use 'primeicons/primeicons.css';
@use 'primeflex/primeflex';

@use './styles/theme/theme.scss';
@use './styles/primeng-fixes.scss';
@use './styles/extensions.scss';

@layer normalize, primeng;

@import 'normalize.css/normalize.css' layer(normalize-reset);
